import React from 'react';
import { NavLink } from 'react-router-dom';

const OnlyHeaderLayout = ({ children }) => {
  return (
    <>
      <div id='page-content-wrapper'>
        <header className='hdtopbar'>
          <div className='row'>
            <div className='col col-sm-5 col-md-5 col-lg-7 col-xl-8'>
              <div className='hd-topbar-links d-flex align-items-center'>
                <div className='logo'>
                  <NavLink to='/'>
                    <img
                      className='logo_main img-responsivex'
                      src='/images/logo.svg'
                      alt=''
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className='content_wrapper'>
          {/* <Outlet /> */}
          {children}
        </div>
      </div>
    </>
  );
};

export default OnlyHeaderLayout;
